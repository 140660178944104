<template>
  <v-container fluid pb-5 mb-4>
    <div v-if="loading" class="primary--text mb-4">
      <v-progress-circular
        indeterminate
        color="primary"
        class="mr-2"
      ></v-progress-circular>

      Cargando los datos, por favor espere...
    </div>

    <v-row v-else>
      <v-col
        v-for="(cupon, itemsIndex) in items"
        :key="itemsIndex"
        cols="12"
        md="3"
        sm="6"
      >
        <v-card>
          <v-img
            :src="cupon.codigo_qr"
            :lazy-src="cupon.codigo_qr"
            aspect-ratio="1"
            class="grey lighten-2"
          >
            <template v-slot:placeholder>
              <v-row
                class="fill-height ma-0"
                align="center"
                justify="center"
              >
                <v-progress-circular
                  indeterminate
                  color="grey lighten-5"
                ></v-progress-circular>
              </v-row>
            </template>
          </v-img>
          <v-card-title class="pt-0 caption">{{ cupon.detalle }}</v-card-title>
        </v-card>
      </v-col>
      <v-col
        v-if="items.length === 0"
        v-show="!loading"
        cols="12"
      >
        <v-card flat>
          <v-card-text>No hay cupones</v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

export default {
  data: () => ({
    loading: true,
    items: [],
    filters: {
      estado_id: 'activo',
      checkCaducados: 'off'
    },
  }),

  methods: {
    query: function () {
        this.loading = true;

        this.$http.get('cuenta/mis_cupones', {params: this.filters})
        .then((response) => {
            this.items = response.data
        })
        .catch((error) => {
            console.log(error);

            this.$eventHub.$emit('snackbar-message', 'Disculpe, no se pudo procesar su solicitud', 'error')
        })
        .then(() => {
            this.loading = false;
        })
    },
  },

  mounted: function () {
      this.query()
  },
}
</script>
